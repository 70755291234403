<!-- 职位详情 -->
<template>
  <div class="mobile-container mobile-recruit-detail" :class="{'fixed': !showMore}" v-title="'职位详情'">
    <div class="header">
      <mobile-header></mobile-header>
    </div>
    <div class="body">
      <div class="content">
        <div class="recruit-status">{{ recruitDetail.type }}</div>
        <div class="recruit-name">{{ recruitDetail.name }}</div>
        <div class="address">{{ recruitDetail.city }}</div>
      </div>
      <div class="content">
        <div class="title">职位描述</div>
        <div class="descript-type duty">岗位职责：</div>
        <div class="descript" v-for="(item, index) in recruitDetail.duty" :key="item">{{ `${index + 1}、${item}`}}</div>
        <div class="descript-type require">任职要求：</div>
        <div class="descript" v-for="(item, index) in recruitDetail.require" :key="item">{{ `${index + 1}、${item}`}}</div>
      </div>
      <div class="content">
        <div class="title">职位详情</div>
        <div class="row">
          <span class="name">发布时间</span>
          <span class="value">{{ recruitDetail.time }}</span>
        </div>
        <div class="row">
          <span class="name">需求人数</span>
          <span class="value">{{ recruitDetail.number }}</span>
        </div>
        <div class="row">
          <span class="name">简历投递邮箱</span>
          <span class="value">{{ recruitDetail.email }}</span>
        </div>
        <div class="btn">
          <a href="tel:027-84683043">
            <img src="@/assets/image/mobile/phone-white.png">
            <span>400-800-4488转84888</span>
          </a>
        </div>
      </div>
    </div>
    <!-- 查看更多 -->
    <div class="more" v-if="!showMore">
      <div class="view-more" @click="showMore = true">查看更多</div>
      <div class="btn">
        <a href="tel:027-84683043">
          <img src="@/assets/image/mobile/phone-white.png">
          <span>400-800-4488转84888</span>
        </a>
      </div>
    </div>
    <!-- 返回主页 -->
    <div class="mobile-back" @click="back">
      <img src="@/assets/image/mobile/icon-33.png">
    </div>
  </div>
</template>

<script>
  // 头部
  import MobileHeader from '@/components/mobile/mobile-header/mobile-header'

  export default {
    name: 'MobileRecruitDetail',
    data () {
      return {
        showMore: false,
        recruitDetail: {}
      }
    },
    components: {
      MobileHeader
    },
    methods: {
      // 返回
      back () {
        this.$router.push({
          path: '/mobileRecruit'
        })
      },
      init () {
        this.recruitDetail = JSON.parse(this.$route.query.row)
      }
    },
    mounted () {
      this.init()
    }
  }
</script>

<style lang="scss">
  @import '@/components/mobile/style/common.scss';

  .mobile-recruit-detail {
    font-family: Source Han Sans CN;
    font-size: $font-size-3;
    font-weight: 300;
    .body {
      padding-top: 1.08rem;
      .content {
        background-color: $white-color;
        padding: $distance-2;
        margin-bottom: $distance-1;
        color: $text-color-4;
        line-height: 0.36rem;
        .recruit-name {
          margin-top: $distance-1;
          font-size: $font-size-7;
          font-weight: 400;
          color: $text-color-1;
        }
        .address {
          margin-top: 0.4rem;
          color: $text-color-3;
        }
        .title {
          font-size: $font-size-1;
          color: $text-color-1;
          & + .row {
            margin-top: 0.5rem;
          }
        }
        .descript-type {
          margin-top: 0.5rem;
          margin-bottom: 0.46rem;
        }
        .descript {
          margin-bottom: 0.43rem;
        }
        .row {
          display: flex;
          justify-content: space-between;
          margin-bottom: $distance-2;
          .value {
            color: $text-color-1;
          }
          & + .btn {
            border-top: 1px solid $border-color-3;
            a {
              margin-top: 0.6rem;
            }
          }
        }
        &.require {
          margin-top: 0.8rem;
        }
        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }
      }
    }
    .btn {
      & > a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 0.92rem;
        background: $text-color-6;
        border-radius: 0.04rem;
        color: $white-color;
        img {
          width: 0.27rem;
          height: 0.29rem;
          margin-right: $distance-2;
        }
      }
    }
    .more {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0 $distance-2 $distance-2;
      background-color: $white-color;
      .view-more {
        color: #EF8723;
        padding: 0.4rem 0;
        text-align: center;
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: -0.5rem;
        height: 0.5rem;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
      }
    }
    &.fixed {
      height: 100%;
      .body {
        padding-bottom: 2.27rem;
        overflow: hidden;
      }
    }
  }
</style>